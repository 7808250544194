<template>
    <div class="dashboard bookings">
        <div class="head">
            <div class="title">Reservaciones</div>
        </div>

        <div class="box-white filter">
            <vs-collapse>
                <vs-collapse-item icon-arrow="expand_more">
                    <div slot="header">
                        Buscador
                    </div>
                    <div class="wrapper">
                        <div class="item">
                            <vs-input label="Referencia" v-model="reference"/>
                        </div>
                        <div class="item">
                            <div class="wrap">
                                <label for="">Fechas</label>
                                <vs-select  class="creSelect" v-model="typeDate">
                                    <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in filterDates" />
                                </vs-select>  
                            </div>
                            <input-date-picker v-model="date" :currentDate="date"></input-date-picker>                
                        </div>
                        <div class="item">
                            <search-supplier @supplier="findSupplier" v-click-outside="closeSuggest" :openSuggestions="openSuggestions"></search-supplier>
                        </div>
                        <div class="item">
                            <label for="">Estado Reservación</label>
                            <multiselect v-model="stBookings" :options="bookStatus" :multiple="true" :max-height="160" placeholder="" 
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="DESCRIPTION" track-by="DESCRIPTION">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item">
                            <label for="">Estado Servicios</label>
                            <multiselect v-model="stServices" :options="optionServices" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados servicios seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item">
                            <vs-select label="Tipo Servicios" multiple v-model="types">
                                <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in typeServices" />
                            </vs-select>
                        </div>
                        <div class="tags">
                            <div class="wrapper-tags">
                                <template v-for="(item, idx) in suppliers">
                                    <vs-chip :key="idx" transparent color="primary" closable @click="removeSupplier(item, 1)">{{ item.NAME }}</vs-chip>
                                </template>
                            </div>
                        </div>
                        
                        <div class="item wrap-btn-actions">
                            <vs-button color="primary"  @click="search()" type="filled" icon="search"></vs-button>
                            <vs-button color="warning"  @click="reset()" type="border" icon="backspace" 
                                v-if="reference != '' || date != '' || stServices != '' || stBookings !='' || types != ''"></vs-button>
                        </div>

                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </div>

        <div class="bookings-wrapper" v-if="status == 'done'">
            <list-bookings :items="bookings"></list-bookings>
            <!-- <pagination :total="totalPost" @handlePagination="handlePagination" :key="key"/> -->
        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import searchSupplier from '@/components/findSupplier.vue'
import listBookings from '@/components/Bookings/_listBookings.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
import BookingService from '@/services/BookingService'
import CatalogService from '@/services/CatalogService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "BookingsView",
    components: {
        modal,
        listBookings,
        searchSupplier,
        inputDatePicker
    },
    data () {
        return {
            key: 1,
            totalPost: 26,
            date: "",
            value: "All",
            reference: "",
            bookings: [],
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stServices: [],
            services: [
                {
                    code: "OK",
                    name: "Confirmed"
                },
                {
                    code: "RQ",
                    name: "Request"
                },
                {
                    code: "CO",
                    name: "Reconfirmed"
                },
                {
                    code: "CD",
                    name: "Confirmed / Differen"
                },
                {
                    code: "PE",
                    name: "Pending"
                },
            ],
            stBookings: [],
            bookStatus: [
                {
                    code: "PA",
                    name: "Partial Allocation"
                },
                {
                    code: "PP",
                    name: "Partial Paid"
                },
                {
                    code: "OK",
                    name: "Confirmed"
                },
                {
                    code: "RE",
                    name: "Reservation"
                },
                {
                    code: "IN",
                    name: "Initial Status"
                },
                {
                    code: "RP",
                    name: "Reservation/Partial Paid"
                }
            ],
            types: [],
            typeServices: [
                {
                    code: 'AC',
                    name: "Accommodation"
                },
                {
                    code: 'CR',
                    name: "Car Rental"
                },
                {
                    code: 'DE',
                    name: "Descriptions"
                },
                {
                    code: 'EN',
                    name: "Entrance Fees"
                },
                {
                    code: 'FB',
                    name: "Food & Beverages"
                },
                {
                    code: 'FT',
                    name: "Flight Tickets"
                },
                {
                    code: 'GU',
                    name: "Guide Services"
                },
                {
                    code: 'MI',
                    name: "Miscellaneous"
                },
                {
                    code: 'TF',
                    name: "Transfers"
                },
                {
                    code: 'TI',
                    name: "Time"
                },
                {
                    code: 'TO',
                    name: "Tours"
                }
            ],

            suppliers: [],
            openSuggestions: true,
            optionServices:[],
            typeDate: "travelDate",
            filterDates: [
                {
                    code: "sellDate",
                    name: "Sell Date"
                },
                {
                    code: "travelDate",
                    name: "Travel Date"
                }
            ]
            
        }
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                console.log(el)
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        await this.getBookings(); 
        await this.getBookingStatus();
        await this.getServiceStatus();
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),

        async getServiceStatus(){
            this.LOADING_STATUS(true);
            this.optionServices = await CatalogService.getServiceStatus({});            
            this.LOADING_STATUS(false);
        },
        async getBookingStatus(){1
            this.LOADING_STATUS(true);
            this.bookStatus = await CatalogService.getBookingStatus({});            
            this.LOADING_STATUS(false);
        },
        async getBookings(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.get({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "reference" : this.reference, //opcional
                "services_status": this.stServices,
                "services_types": this.types,
                "booking_status": this.stBookings,
                "suppliers": this.suppliers,
                "dateFilter": this.dateFilter,
            });            
            if((response.status/100) == 2){
                this.bookings = response.data
            }            
            this.LOADING_STATUS(false);
        },
        handlePagination (_paged) {
            this.filter.page = _paged    
            // this.retrieveProperties()
        },
        search() {
            this.getBookings()
        },
        reset() {
            this.date = "";
            this.reference = "";
            this.stServices = [];
            this.stBookings = [];
            this.types = [];
            this.getBookings();
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
        findSupplier(response) {
            if(this.suppliers.findIndex(item => item.CODE == response.CODE) < 0){
                this.suppliers.push(response)
            }
        },
        removeSupplier(item) {
            this.suppliers.splice(this.suppliers.indexOf(item), 1)
        },
        closeSuggest(){
            this.openSuggestions = !this.openSuggestions
        },
        
    }
}
</script>