<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="ServType" class="center">Tipo</vs-th>
                    <vs-th sort-key="TravelDate">Fecha Viaje</vs-th>
                    <vs-th sort-key="BookName">Booking Info</vs-th>
                    <vs-th sort-key="BookReference">Referencia</vs-th>
                    <!-- <vs-th sort-key="SellDate">Sell Date</vs-th> -->
                    <vs-th sort-key="Supplier">Proveedor</vs-th>
                    <vs-th sort-key="ServDescription">Servicio</vs-th>
                    <vs-th sort-key="status">Estado</vs-th>
                    <vs-th sort-key="In">Entrada</vs-th>
                    <vs-th sort-key="Out">Salida</vs-th>
                    <vs-th class="center"></vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].ServType" class="center">
                                <!-- {{ data[indextr].ServType }} -->
                                <img class="icon-table" :src="require(`@/assets/images/booking/${data[indextr].ServType}.svg`)" alt="" />
                            </vs-td>
                            <vs-td :data="data[indextr].TravelDate"> {{ data[indextr].TravelDate | moment('timezone', 'Etc/GMT', 'll')}} </vs-td>
                            <vs-td :data="data[indextr].BookName"> {{ data[indextr].BookName }} </vs-td>
                            <vs-td :data="data[indextr].BookReference" class="center"> {{ data[indextr].BookReference }} </vs-td>
                            <!-- <vs-td :data="data[indextr].SellDate" class="center"> {{ data[indextr].SellDate }} </vs-td> -->
                            <vs-td :data="data[indextr].Supplier" class="center"> {{ data[indextr].Supplier }} </vs-td>
                            <vs-td :data="data[indextr].ServDescription"> {{ data[indextr].ServDescription }} </vs-td>
                            <vs-td :data="data[indextr].ServStatus" class="center"> {{ data[indextr].ServStatus }} </vs-td>
                            <vs-td :data="data[indextr].In" class="center"> {{ data[indextr].In | moment('timezone', 'Etc/GMT', 'lll') }} </vs-td>
                            <vs-td :data="data[indextr].Out" class="center"> {{ data[indextr].Out | moment('timezone', 'Etc/GMT', 'lll') }} </vs-td>
                            <vs-td class="options">
                                <vs-icon icon="expand_more" @click="toggleRow(indextr)"></vs-icon>
                            </vs-td> 
                        </vs-tr>
                        <vs-tr :key="`sub-${indextr}`" v-if="opened.includes(indextr)">
                            <vs-td colspan="10" class="noLineClamp">
                                <div class="bookingInfo">
                                    <div class="travel-info">
                                        <div class="item">
                                            <span class="subtitle">Fecha Entrada</span>
                                            <p>{{  data[indextr].In | moment('timezone', 'Etc/GMT', 'lll') }}</p>
                                            <div class="notes">
                                                <p>{{  data[indextr].PU }}</p>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Fecha Salida</span>
                                            <p>{{  data[indextr].Out | moment('timezone', 'Etc/GMT', 'lll') }}</p>
                                            <div class="notes">
                                                <p>{{  data[indextr].DO }}</p>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Cantidad de viajeros</span>
                                            <p>{{  data[indextr].Pax }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Proveedor</span>
                                            <p>{{  data[indextr].SupplierName }}</p>
                                        </div>
                                    </div>
                                    <div class="service">
                                        <div class="notes">
                                            <div class="subtitle">Venta del servicio</div>
                                            <p>{{ data[indextr].SellDate }}</p>
                                            <span class="voucher">Voucher: {{ data[indextr].ServVoucher }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Consultant</div>
                                            <p>{{ data[indextr].BookConsultant }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Book Status</div>
                                            <p>{{ data[indextr].BookStatus }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Código del Agente</div>
                                            <p>{{ data[indextr].AgentCode }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Confirmación del servicio</div>
                                            <p>{{ data[indextr].ServConfirmation }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Comentarios</div>
                                            <p>{{ data[indextr].ServRemarks }}</p>
                                        </div>
                                    </div>
                                    <!-- <p>{{ data[indextr].'D.O' </p> -->
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableBookingList",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items
        }
    },
    watch: {
        items(val){
            this.bookings = val
        }
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        },
    }
}
</script>